import React, { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import emailjs from "@emailjs/browser";
import SectionTitle from "../../../components/SectionTitle";
import { useData } from "../../../services/DataProvider";
import { useCookies } from "react-cookie";

const ContactForm = () => {
  const { data } = useData();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSending, setIsSending] = useState(false);

  const [cookies] = useCookies(["language"]);

  const formRef = useRef(null);
  const formInView = useInView(formRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs.init({
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      // Do not allow headless browsers
      blockHeadless: true,
      limitRate: {
        id: "app",
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData
      )
      .then(
        (result) => {
          console.log(result.text);

          setIsSending(false);
          setFormData({
            name: "",
            email: "",
            message: "",
          });

          alert(
            cookies.language === "hr"
              ? "Poruka uspješno poslana!"
              : "Message sent successfully!"
          );
        },
        (error) => {
          console.log(error.text);
          alert(
            cookies.language === "hr"
              ? "Poruku trenutno nije moguće poslati. Molimo Vas da pokušate ponovo kasnije."
              : "Message could not be sent. Please try again later."
          );
          setIsSending(false);
        }
      );
  };

  return (
    <section className="py-16 px-3 bg-gray-100">
      <SectionTitle
        title={data.contact.content.section2.title}
        description={data.contact.content.section2.description}
      />

      <div className="text-center mb-5 px-2">
        <motion.form
          ref={formRef}
          className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: formInView ? 1 : 0, y: formInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2" htmlFor="name">
              {data.contact.content.section2.form.input1}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`w-full px-4 py-2 border ${
                isSending ? "bg-gray-200" : "bg-white"
              } border-gray-300 rounded-lg`}
              value={formData.name}
              onChange={handleChange}
              disabled={isSending}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2" htmlFor="email">
              {data.contact.content.section2.form.input2}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`w-full px-4 py-2 border ${
                isSending ? "bg-gray-200" : "bg-white"
              } border-gray-300 rounded-lg`}
              value={formData.email}
              onChange={handleChange}
              disabled={isSending}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-2"
              htmlFor="message"
            >
              {data.contact.content.section2.form.input3}
            </label>
            <textarea
              id="message"
              name="message"
              className={`w-full px-4 py-2 border ${
                isSending ? "bg-gray-200" : "bg-white"
              } border-gray-300 rounded-lg`}
              rows="6"
              value={formData.message}
              onChange={handleChange}
              disabled={isSending}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className={`px-6 py-3 font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105 ${
              isSending ? "bg-sky-800" : "bg-sky-500 hover:bg-sky-600"
            } text-white`}
            disabled={isSending}
          >
            {isSending
              ? `${data.contact.content.section2.form.button.disabled}`
              : `${data.contact.content.section2.form.button.active}`}
          </button>
        </motion.form>
      </div>
    </section>
  );
};

export default ContactForm;
